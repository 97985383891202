<template>
  <a-select
    show-search
    :mode="mode"
    v-model="associatesProxy"
    :autoClearSearchValue="false"
    :allow-clear="true"
    :loading="loadingAssociated"
    :filter-option="false"
    :disabled="disabled"
    placeholder="Associate"
    @search="searchAssociates"
    @focus="focusAssociates"
    @blur="blurAssociates"
  >
    <template v-if="isAssociateId">
      <a-select-option
        v-for="user in users"
        :key="user.associate_id"
        :value="user.associate_id"
      >
        {{ `[${user.employee_id}] ${user.first_name} ${user.last_name}` }}
      </a-select-option>
    </template>
    <template v-else>
      <a-select-option
        v-for="user in users"
        :key="user.employee_id"
        :value="user.employee_id"
      >
        {{ `[${user.employee_id}] ${user.first_name} ${user.last_name}` }}
      </a-select-option>
    </template>
  </a-select>
</template>

<script>
import { debounce } from "@/helpers/debounce";
import { notification, Select } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: searchState, mapActions: searchActions } =
  createNamespacedHelpers("search");

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    multiply: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String | Array,
      default: undefined,
    },
    isAssociateId: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingAssociated: false,

      searchQuery: "",
    };
  },
  watch: {
    searchQuery: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.fetchAssociates();
      },
      immediate: true,
    },
  },
  computed: {
    ...searchState({
      users: (state) => state.users,
    }),

    mode() {
      return this.multiply ? "multiple" : "default";
    },

    associatesProxy: {
      get() {
        if (this.isAssociateId) {
          if (Array.isArray(this.value)) {
            return this.value.map(Number);
          }
          return this.value ? Number(this.value) : undefined;
        }
        return this.value || undefined;
      },
      set(value) {
        if (value === undefined) {
          this.searchQuery = "";
        }

        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    ...searchActions(["getUsers"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    searchAssociates: debounce(function (value) {
      this.searchQuery = value;
    }, 1000),

    focusAssociates() {
      this.searchQuery = "";
    },

    blurAssociates() {
      this.searchQuery = "";
    },

    // async calls

    async fetchAssociates() {
      this.loadingAssociated = true;
      try {
        await this.getUsers({ query: this.searchQuery });
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingAssociated = false;
      }
    },
  },
};
</script>
