<template>
  <div>
    <h3>Attachments</h3>
    <a-table
      :rowKey="(record) => record.id"
      :locale="{ emptyText: 'Attachments Not Found' }"
      size="small"
      :columns="columns"
      :data-source="files"
      :scroll="{ y: 300 }"
    >
      <span slot="actions" slot-scope="text, record">
        <a-button icon="download" @click="downloadFile(record.url)">
          Download
        </a-button>
      </span>
    </a-table>
  </div>
</template>

<script>
import { Button, Table } from "ant-design-vue";

export default {
  components: {
    "a-table": Table,
    "a-button": Button,
  },
  props: {
    files: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      columns: [
        {
          title: "Name",
          dataIndex: "url",
        },
        {
          title: "Actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  methods: {
    downloadFile(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
