import util from "@/util";
import moment from "moment-timezone";
import shiftsHelper from "@/helpers/shifts";

export default {
  mixins: [shiftsHelper],
  watch: {
    "shiftForm.work_start"() {
      this.signedDuration;
    },
    "shiftForm.work_end"() {
      this.signedDuration;
    },
    "shiftForm.lunch_start"() {
      this.lunchDuration;
    },
    "shiftForm.lunch_end"() {
      this.lunchDuration;
    },
    "shiftForm.second_lunch_start"() {
      this.secondLunchDuration;
    },
    "shiftForm.second_lunch_end"() {
      this.secondLunchDuration;
    },
  },
  computed: {
    // formatters

    dateTimeFormat() {
      return this.$store.state.applicationState.dateTimeFormat;
    },
    dateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    timeFormat() {
      return this.$store.state.applicationState.timeFormat;
    },
    timeZone() {
      return this.$store.state.applicationState.timeZone;
    },
    minLunchDuration() {
      return this.$store.state.applicationState.timeConstants.lunch_duration;
    },
    minSecondLunchDuration() {
      return this.$store.state.applicationState.timeConstants
        .second_lunch_duration;
    },
    minWorkDuration() {
      return this.$store.state.applicationState.timeConstants.min_work_duration;
    },
    minWorkWithWaiverDuration() {
      return this.$store.state.applicationState.timeConstants
        .min_work_with_waiver_duration;
    },
    maxWorkDuration() {
      return this.$store.state.applicationState.timeConstants.max_work_duration;
    },
    longWorkDuration() {
      return this.$store.state.applicationState.timeConstants
        .long_work_duration;
    },
    longWorkWithWaiverDuration() {
      return this.$store.state.applicationState.timeConstants
        .long_work_with_waiver_duration;
    },

    // available fields

    isWorkEmpty() {
      return !this.shiftForm.work_start || !this.shiftForm.work_end;
    },

    isLunchEmpty() {
      return !this.shiftForm.lunch_start || !this.shiftForm.lunch_end;
    },

    isSecondLunchEmpty() {
      return (
        !this.shiftForm.second_lunch_start || !this.shiftForm.second_lunch_end
      );
    },

    isLunchDisabled() {
      return this.isWorkEmpty || this.isLunchWaived;
    },

    isLunchEndDisabled() {
      return this.isLunchDisabled || !this.shiftForm.lunch_start;
    },

    isSecondLunchDisabled() {
      return (
        this.isWorkEmpty ||
        (this.isLunchEmpty && !this.isLunchWaived) ||
        this.isSecondLunchWaived
      );
    },

    isSecondLunchEndDisabled() {
      return this.isSecondLunchDisabled || !this.shiftForm.second_lunch_start;
    },

    isLunchWaiveAvailable() {
      if (this.isWorkEmpty) {
        this.isLunchWaived = false;

        return false;
      }

      const calculation =
        this.signedDuration >= this.minWorkDuration &&
        this.signedDuration < this.minWorkWithWaiverDuration;

      if (!calculation) this.isLunchWaived = false;

      return calculation;
    },

    isSecondLunchWaiveAvailable() {
      if (this.isWorkEmpty || this.isLunchEmpty) {
        this.isSecondLunchWaived = false;

        return false;
      }

      const calculation =
        this.signedDuration - this.lunchDuration >= this.longWorkDuration &&
        this.signedDuration - this.lunchDuration <
          this.longWorkWithWaiverDuration;

      if (!calculation) this.isSecondLunchWaived = false;

      return calculation;
    },

    // alerts
    lunchWaiveInfo() {
      return this.getLunchWaiveInfoMessage(true);
    },

    secondLunchWaiveInfo() {
      return this.getLunchWaiveInfoMessage(false);
    },

    signedDuration() {
      return this.calculateDuration("work");
    },

    lunchDuration() {
      return this.calculateDuration("lunch");
    },

    secondLunchDuration() {
      return this.calculateDuration("second_lunch");
    },

    workDuration() {
      return (
        this.signedDuration - this.lunchDuration - this.secondLunchDuration
      );
    },

    // validators

    workEndInitialTime() {
      const nonScheduleInitialTimeConstraint = moment(
        this.shiftForm.work_start
      ).add(510, "minutes");

      const value = this.shiftForm.work_end ?? nonScheduleInitialTimeConstraint;

      return this.resetSeconds(value);
    },

    validateWorkStart() {
      if (!this.shiftForm.work_start)
        return "Field is required and can not be empty.";

      if (!this.shiftForm.work_end) return null;

      if (this.workDuration > this.maxWorkDuration) {
        return `Please do not work ${this.minutesToHoursAndMinutes(
          this.maxWorkDuration
        )} or more unless you take a ${
          this.minLunchDuration
        }-minute meal break.`;
      }

      if (
        moment(this.shiftForm.work_start).isSameOrAfter(this.shiftForm.work_end)
      ) {
        return "Work start must be before work end";
      } else {
        return null;
      }
    },

    validateWorkEnd() {
      if (!this.shiftForm.work_end)
        return "Field is required and can not be empty.";

      if (!this.shiftForm.work_start) return null;

      if (this.workDuration > this.maxWorkDuration) {
        return `Please do not work ${this.minutesToHoursAndMinutes(
          this.maxWorkDuration
        )} or more unless you take a ${
          this.minLunchDuration
        }-minute meal break.`;
      }

      if (
        moment(this.shiftForm.work_start).isSameOrAfter(
          moment(this.shiftForm.work_end)
        )
      ) {
        return "Work end must be after work start";
      } else {
        return null;
      }
    },

    lunchStartMinTime() {
      const value = this.shiftForm.work_start ?? this.shiftForm.scheduled_start;

      return this.resetSeconds(value.clone().add(60, "minutes"));
    },

    lunchStartInitialTime() {
      const value = this.shiftForm.lunch_start ?? this.lunchStartMinTime;

      return this.resetSeconds(value);
    },

    lunchEndMinTime() {
      const value = moment(this.shiftForm.lunch_start).add(
        this.minLunchDuration,
        "minutes"
      );

      return this.resetSeconds(value);
    },

    lunchEndInitialTime() {
      const value = this.shiftForm.lunch_end ?? this.lunchEndMinTime;

      return this.resetSeconds(value);
    },

    isValidFirstLunchStart() {
      const isStartValid = this.validateFirstLunchStart === null;
      const isEndValid = this.validateFirstLunchEnd === null;
      const isValid = isStartValid && isEndValid;

      if (!isValid) {
        return false;
      }

      if (
        this.lunchDuration < this.minLunchDuration ||
        this.lunchDuration < 0
      ) {
        return false;
      }

      return true;
    },

    validateFirstLunchStart() {
      if (!this.shiftForm.work_start && !this.shiftForm.work_end) {
        return "Work times must be specified first";
      }

      if (!this.shiftForm.lunch_start) {
        return "Field is required and can not be empty.";
      }

      if (
        moment(this.shiftForm.lunch_start).isSameOrBefore(
          this.shiftForm.work_start
        ) ||
        moment(this.shiftForm.lunch_start).isSameOrAfter(
          this.shiftForm.work_end
        )
      ) {
        return "Meal break start must be within working time";
      } else if (
        moment(this.shiftForm.lunch_start).isSameOrAfter(
          this.shiftForm.lunch_end
        )
      ) {
        return "Meal break start must be before meal break end";
      } else {
        return null;
      }
    },

    validateFirstLunchEnd() {
      if (!this.shiftForm.work_start && !this.shiftForm.work_end) {
        return "Work times must be specified first";
      }

      if (!this.shiftForm.lunch_end) {
        return "Field is required and can not be empty.";
      }

      if (!this.shiftForm.lunch_start) {
        return null;
      }

      if (
        moment(this.shiftForm.lunch_end).isSameOrBefore(
          this.shiftForm.work_start
        ) ||
        moment(this.shiftForm.lunch_end).isSameOrAfter(this.shiftForm.work_end)
      ) {
        return "Meal break end must be within working time";
      } else if (
        this.shiftForm.lunch_end.isSameOrBefore(this.shiftForm.lunch_start)
      ) {
        return "Meal break end must be after meal break start";
      } else {
        return null;
      }
    },

    isValidSecondLunchStart() {
      const isStartValid = this.validateSecondLunchStart === null;
      const isEndValid = this.validateSecondLunchEnd === null;
      const isValid = isStartValid && isEndValid;

      if (!isValid) {
        return false;
      }

      if (
        this.secondLunchDuration < this.minSecondLunchDuration ||
        this.secondLunchDuration < 0
      ) {
        return false;
      }

      return true;
    },

    secondLunchStartMinTime() {
      const value = this.shiftForm.lunch_end.clone().add(60, "minutes");

      return this.resetSeconds(value);
    },

    secondLunchStartInitialTime() {
      const value =
        this.shiftForm.second_lunch_start ?? this.secondLunchStartMinTime;

      return this.resetSeconds(value);
    },

    validateSecondLunchStart() {
      if (!this.shiftForm.work_start && !this.shiftForm.work_end) {
        return "Work times must be specified first";
      }

      if (!this.shiftForm.lunch_start && !this.shiftForm.lunch_end) {
        return "Meal break times must be specified first";
      }

      if (!this.shiftForm.second_lunch_start) {
        return "Field is required and can not be empty.";
      }

      if (
        moment(this.shiftForm.second_lunch_start).isSameOrBefore(
          this.shiftForm.lunch_end
        ) ||
        moment(this.shiftForm.second_lunch_start).isSameOrAfter(
          this.shiftForm.work_end
        )
      ) {
        return "Second meal break start must be after first meal break and must be within working time";
      } else if (
        moment(this.shiftForm.second_lunch_start).isAfter(
          this.shiftForm.second_lunch_end
        )
      ) {
        return "Second meal break start must be before its end";
      } else {
        return null;
      }
    },

    secondLunchEndMinTime() {
      const value = moment(this.shiftForm.second_lunch_start).add(
        this.minSecondLunchDuration,
        "minutes"
      );

      return this.resetSeconds(value);
    },

    secondLunchEndInitialTime() {
      return this.shiftForm.second_lunch_end ?? this.secondLunchEndMinTime;
    },

    validateSecondLunchEnd() {
      if (!this.shiftForm.work_start && !this.shiftForm.work_end) {
        return "Work times must be specified first";
      }

      if (!this.shiftForm.lunch_start && !this.shiftForm.lunch_end) {
        return "Meal break times must be specified first";
      }

      if (!this.shiftForm.second_lunch_end) {
        return "Field is required and can not be empty.";
      }

      if (!this.shiftForm.second_lunch_start) {
        return null;
      }

      if (
        moment(this.shiftForm.second_lunch_end).isSameOrBefore(
          this.shiftForm.lunch_end
        ) ||
        moment(this.shiftForm.second_lunch_end).isSameOrAfter(
          this.shiftForm.work_end
        )
      ) {
        return "Second meal break end must be after first meal break and must be within working time";
      } else if (
        this.shiftForm.second_lunch_end.isSameOrBefore(
          this.shiftForm.second_lunch_start
        )
      ) {
        return "Second meal break end must be after its start";
      } else {
        return null;
      }
    },
  },

  methods: {
    calculateDuration(prefix) {
      const start_date = this.shiftForm[`${prefix}_start`];
      const end_date = this.shiftForm[`${prefix}_end`];

      if (!start_date || !end_date) {
        if (prefix === "work") {
          this.shiftForm.duration = 0;
        } else {
          this.shiftForm[`${prefix}_duration`] = 0;
        }
        return 0;
      }

      const start = moment(start_date).clone().startOf("minute");
      const end = moment(end_date).clone().startOf("minute");

      if (prefix === "work") {
        this.shiftForm.duration = end.diff(start, "minutes");
      } else {
        this.shiftForm[`${prefix}_duration`] = end.diff(start, "minutes");
      }

      return end.diff(start, "minutes");
    },

    calculateDisabledDateTimeRange(
      start,
      end,
      globalStart,
      globalEnd,
      pickerType
    ) {
      const hourRange = this.range(0, 24);
      const minuteRange = this.range(0, 60);

      const adjustedStart = start
        ? moment.max(start, globalStart)
        : globalStart.clone();
      const adjustedEnd = end ? moment.min(end, globalEnd) : globalEnd.clone();

      let disabledHours = [];
      let disabledMinutes = (selectedHour) => [];

      if (pickerType === "start") {
        disabledHours = hourRange.filter((hour) => {
          if (adjustedStart.isSame(globalStart, "day")) {
            return hour < globalStart.hours();
          }
          if (adjustedStart.isSame(globalEnd, "day")) {
            return hour > globalEnd.hours();
          }
          return false;
        });

        disabledMinutes = (selectedHour) => {
          if (
            adjustedStart.isSame(globalStart, "day") &&
            selectedHour === globalStart.hours()
          ) {
            return minuteRange.filter((m) => m < globalStart.minutes());
          }
          if (
            adjustedStart.isSame(globalEnd, "day") &&
            selectedHour === globalEnd.hours()
          ) {
            return minuteRange.filter((m) => m > globalEnd.minutes());
          }
          return [];
        };
      }

      if (pickerType === "end") {
        disabledHours = hourRange.filter((hour) => {
          if (adjustedEnd.isSame(globalStart, "day")) {
            return hour < globalStart.hours();
          }
          if (adjustedEnd.isSame(globalEnd, "day")) {
            return hour > globalEnd.hours();
          }
          return false;
        });

        disabledMinutes = (selectedHour) => {
          if (
            adjustedEnd.isSame(globalStart, "day") &&
            selectedHour === globalStart.hours()
          ) {
            return minuteRange.filter((m) => m < globalStart.minutes());
          }
          if (
            adjustedEnd.isSame(globalEnd, "day") &&
            selectedHour === globalEnd.hours()
          ) {
            return minuteRange.filter((m) => m > globalEnd.minutes());
          }
          return [];
        };
      }

      return {
        disabledHours: () => disabledHours,
        disabledMinutes,
      };
    },

    getDisabledTime(type, period) {
      const startKey = `${type}_start`;
      const endKey = `${type}_end`;

      return this.calculateDisabledDateTimeRange(
        this.shiftForm[startKey],
        this.shiftForm[endKey],
        this.shiftForm.work_start,
        this.shiftForm.work_end,
        period
      );
    },

    disabledLunchStartTime() {
      return this.getDisabledTime("lunch", "start");
    },

    disabledLunchEndTime() {
      return this.getDisabledTime("lunch", "end");
    },

    disabledSecondLunchStartTime() {
      return this.getDisabledTime("second_lunch", "start");
    },

    disabledSecondLunchEndTime() {
      return this.getDisabledTime("second_lunch", "end");
    },

    resetSeconds(date) {
      if (date) {
        return date.clone().seconds(0).milliseconds(0);
      }
      return date;
    },

    range(start, end) {
      return start < end
        ? Array.from({ length: end - start }, (_, i) => i + start)
        : [];
    },

    // disabled fields logic

    disabledSignedStartDate(date) {
      if (!this.shiftForm.work_end) {
        return false;
      }

      return this.shiftForm.work_end.isBefore(moment(date), "day");
    },

    disabledSignedEndDate(date) {
      if (!this.shiftForm.work_start) {
        return false;
      }

      return this.shiftForm.work_start.isAfter(moment(date), "day");
    },

    disabledLunchStartDate(date) {
      if (!this.shiftForm.work_start || !this.shiftForm.work_end) {
        return true;
      }

      return (
        this.shiftForm.work_start.isAfter(moment(date), "day") ||
        this.shiftForm.work_end.isBefore(moment(date), "day")
      );
    },

    disabledLunchEndDate(date) {
      if (
        !this.shiftForm.work_start ||
        !this.shiftForm.work_end ||
        !this.shiftForm.lunch_start
      ) {
        return true;
      }

      return (
        this.shiftForm.work_end.isBefore(moment(date), "day") ||
        this.shiftForm.lunch_start.isAfter(moment(date), "day")
      );
    },

    disabledSecondLunchStartDate(date) {
      if (!this.shiftForm.work_start || !this.shiftForm.work_end) {
        return true;
      }

      return (
        this.shiftForm.work_start.isAfter(moment(date), "day") ||
        this.shiftForm.work_end.isBefore(moment(date), "day")
      );
    },

    disabledSecondLunchEndDate(date) {
      if (
        !this.shiftForm.work_start ||
        !this.shiftForm.work_end ||
        !this.shiftForm.second_lunch_start
      ) {
        return true;
      }

      return (
        this.shiftForm.work_start.isAfter(moment(date), "day") ||
        this.shiftForm.work_end.isBefore(moment(date), "day")
      );
    },

    getLunchWaiveInfoMessage(isFirstLunch) {
      const messages = [];

      if (!this.isLunchWaiver) {
        messages.push("This associate does not have a lunch waiver.");

        return messages;
      }

      if (isFirstLunch && !this.isLunchWaiveAvailable) {
        messages.push(
          "An associate can waive the first lunch period if work time is 6 hours or less."
        );
      }

      if (!isFirstLunch && !this.isSecondLunchWaiveAvailable) {
        messages.push(
          "An associate can waive the second lunch period if they work more than 10 but less than 12 hours, and only if they have already taken the first lunch."
        );
      }

      if (!isFirstLunch) {
        messages.push(
          "If the work hours exceed 12 hours, the second lunch period cannot be waived."
        );
      }

      return messages;
    },

    formatTimeFields(payload) {
      const formattedPayload = { ...payload };

      const timeFields = [
        "work_start",
        "work_end",
        "lunch_start",
        "lunch_end",
        "second_lunch_start",
        "second_lunch_end",
        "actual_lunch_end",
        "actual_lunch_start",
        "actual_second_lunch_end",
        "actual_second_lunch_start",
        "actual_work_end",
        "actual_work_start",
      ];

      timeFields.forEach((field) => {
        if (formattedPayload[field]) {
          formattedPayload[field] = moment
            .utc(formattedPayload[field])
            .tz(this.timeZone);
        }
      });

      return formattedPayload;
    },
  },
};
