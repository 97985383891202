import apiClient from "@/services/api";

export default {
  async getPayments(
    { commit, rootState },
    { page = 1, size = 50, filter = {} }
  ) {
    try {
      const { data } = await apiClient.post(
        "/api/payment_requests/project/get_all",
        {
          project_id: rootState.applicationState.currentProject.id,
          page,
          size,
          filter,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ??
            "An error occurred while loading the payment requests. Please try again later"
          } Please try again later`
        );
      }

      commit("setPayments", data);
    } catch (error) {
      throw error.message;
    }
  },

  async getPayment({ commit }, { payment_id }) {
    try {
      const { data } = await apiClient.post(
        `api/payment_requests/${payment_id}/get`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ??
            "An error occurred while loading the payment request. Please try again later"
          } Please try again later`
        );
      }

      commit("setPayment", data);
    } catch (error) {
      throw error.message;
    }
  },

  async approvePaymentRequest(_, { request_id }) {
    try {
      const { data } = await apiClient.post(
        `api/payment_requests/${request_id}/set_approve`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async approveShiftPayments(_, { request_id, shift_payment_ids }) {
    try {
      const { data } = await apiClient.post(
        `api/payment_requests/${request_id}/shift_payments/approve`,
        { shift_payment_ids }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async declineShiftPayments(_, { request_id, shift_payment_ids }) {
    try {
      const { data } = await apiClient.post(
        `api/payment_requests/${request_id}/shift_payments/decline`,
        { shift_payment_ids }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getPaymentSystems({ commit, rootState }) {
    try {
      const { data } = await apiClient.post(
        "/api/get_project_payment_systems",
        {
          project_id: rootState.applicationState.currentProject.id,
        }
      );
      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      const updatedSystems = data.payment_systems.filter(
        (item) => item.enabled
      );

      commit("setSystems", updatedSystems);
    } catch (error) {
      throw error.message;
    }
  },

  async getPaymentBalance({}, { payment_system_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/payment_systems/${payment_system_id}/balance`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      return data.balance;
    } catch (error) {
      throw error.message;
    }
  },
};
