<template>
  <div>
    <h3>Payments</h3>

    <payment-info :payment-id.sync="paymentId" @change="reloadPayments" />

    <a-table
      :rowKey="(record) => record.id"
      :locale="{ emptyText: 'Payments Not Found' }"
      size="small"
      :columns="columns"
      :data-source="payments"
      :pagination="false"
      :loading="loadingPayments"
      :scroll="{ y: 300 }"
    >
      <span slot="status" slot-scope="text, record">
        <a-tag class="m-0" :color="getShiftPaymentStatus(record.status).color">{{
          getShiftPaymentStatus(record.status).title
        }}</a-tag>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button
          icon="dollar"
          @click="openPaymentModal(record.payment_request_id)"
          >Open Payment</a-button
        >
      </span>
    </a-table>
  </div>
</template>

<script>
import PaymentInfo from "@/modules/payment/components/payment-info.vue";
import { Button, Table, Tag } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: shiftState,
  mapActions: shiftActions,
  mapGetters: shiftGetters,
} = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-table": Table,
    "a-tag": Tag,
    "a-button": Button,
    "payment-info": PaymentInfo,
  },
  computed: {
    ...shiftState({
      payments: (state) => state.shiftPayments,
    }),
    ...shiftGetters(["getActiveShiftId", "getShiftPaymentStatus"]),
  },
  data() {
    return {
      paymentId: undefined,
      loadingPayments: false,

      columns: [
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Status",
          dataIndex: "status",
          align: "center",
          width: "150px",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Total",
          dataIndex: "amount",
          align: "center",
          width: "100px",
          customRender: (_, record) => {
            return `$${record.amount / 100}`;
          },
        },
        {
          title: "Actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  methods: {
    ...shiftActions(["getShiftPayments"]),
    openPaymentModal(id) {
      this.paymentId = id;
    },

    async reloadPayments() {
      try {
        this.loadingPayments = true;
      } catch {
        const payload = {
          shift_id: this.getActiveShiftId(),
        };

        await this.getShiftPayments(payload);
      } finally {
        this.loadingPayments = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .ant-table-footer {
  padding: 8px;
}

::v-deep .ant-table-header {
  scrollbar-width: none;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 8px;
}
</style>
