import { PAYMENT_STATUS } from "./constants";

export default {
  getPaymentStatus() {
    return (status, method) => {
      const initialStatus = status.toUpperCase();

      if (method == "Manual" && initialStatus === "PENDING") {
        return {
          title: "Awaiting payment",
          color: "#ffb546",
        };
      }

      return (
        PAYMENT_STATUS[initialStatus.toUpperCase()] ?? PAYMENT_STATUS.CREATED
      );
    };
  },
  getStripePaymentSystem(state, _, rootState) {
    const currentProjectId = rootState.applicationState.currentProject.id;

    return (id = null) => {
      return state.systems.find((item) => {
        const projectAssociation = item.payment_system_project_association.find(
          (project) => project.project.id === currentProjectId
        );

        const conditions =
          projectAssociation &&
          item.template_id === 2 &&
          !item.is_global &&
          projectAssociation.configs.enabled === true &&
          projectAssociation.configs.activated === true;

        if (id !== null) {
          return item.id === id && conditions;
        }

        return conditions;
      });
    };
  },
};
