<template>
  <div>
    <h3>Activity Info</h3>
    <div v-if="loadingAssociate" class="loading-spin">
      <a-spin />
    </div>
    <template v-else
      ><div class="grid" style="width: 400px">
        <b class="col-6">Hire Date</b>
        <div class="col-6 text-right">
          {{
            associate?.hire_date
              ? formatDateString(associate?.hire_date)
              : " - "
          }}
        </div>

        <b class="col-6">Seniority Date</b>
        <div class="col-6 text-right">
          {{
            associate?.seniority_date
              ? formatDateString(associate?.seniority_date)
              : " - "
          }}
        </div>

        <template v-if="isFutureTermination"
          ><b class="col-6">Future Termination</b>
          <div class="col-6 text-right">
            {{
              associate?.resigned_date
                ? formatDateString(associate?.resigned_date)
                : " - "
            }}
          </div>
        </template>
      </div>
      <div class="grid" style="width: 400px">
        <template v-if="!isTerminated">
          <b class="col-6">Status</b>
          <div class="col-6 text-right">
            <a-tag class="m-0" :color="getStatus.color">{{
              getStatus.title
            }}</a-tag>
          </div>

          <b class="col-6">Author</b>
          <div class="col-6 text-right">{{ terminationAuthorName }}</div>

          <b class="col-6">Reason</b>
          <div class="col-6 text-right">{{ associate.termination.reason }}</div>

          <b class="col-6">Terminated Date</b>
          <div class="col-6 text-right">
            {{ formatDateString(associate.termination.termination_date) }}
          </div>

          <b class="col-6">Submission Date</b>
          <div class="col-6 text-right">
            {{ formatDateString(associate.termination.created_at) }}
          </div>

          <div class="col-12 flex gap-2">
            <a-button icon="redo" @click="openActivityConfirm(true)"
              >Rehire</a-button
            ><a-button
              v-if="associate.is_active"
              icon="redo"
              @click="openActivityConfirm(false)"
              >Disable</a-button
            >
          </div>
        </template>

        <template v-else>
          <div class="col-12 flex gap-2" v-if="!creatingTermination">
            <a-button @click="switchSetTermination" type="danger"
              >Create Termination</a-button
            >
          </div>

          <template v-else>
            <b class="col-6"
              >Terminated Date <span class="required-field">*</span></b
            >
            <div class="col-6 text-right">
              <a-date-picker
                v-model="newTermination.termination_date"
                placeholder="Enter termination date"
                class="w-full"
                :format="visualDateFormat"
              />
            </div>

            <b class="col-6">Reason <span class="required-field">*</span></b>
            <div class="col-6 text-right">
              <a-textarea
                v-model="newTermination.reason"
                :autoSize="true"
                placeholder="Enter termination reason"
              />
            </div>
            <div class="col-10">
              <p class="text-lg font-bold m-0">
                Terminate ALL Associate Profiles
              </p>
              <p class="m-0">
                Associate will be terminated and will completely lose access to
                the company. Do not use this option when closing a specific
                Associate Profile.
              </p>
            </div>

            <a-checkbox
              class="col-2 text-right"
              :checked="terminateAll"
              @change="handleCheckTerminateAll"
            />

            <div class="col-12 flex gap-2 justify-content-end">
              <a-button @click="cancelTermination">Cancel</a-button>
              <a-button
                type="danger"
                @click="openTerminationConfirm"
                :disabled="saveTerminationDisabled"
              >
                Terminate
              </a-button>
            </div>
          </template>
        </template>
      </div></template
    >
  </div>
</template>

<script>
import Util from "@/util";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  notification,
  Spin,
  Tag,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";

const { mapActions: profileActions } = createNamespacedHelpers("profile");

const StatusTagColors = Object.freeze({
  GRAY: "#b4b4b4",
  GREEN: "#34db30",
  RED: "#ff4646",
});

export default {
  components: {
    "a-button": Button,
    "a-date-picker": DatePicker,
    "a-textarea": Input.TextArea,
    "a-tag": Tag,
    "a-checkbox": Checkbox,
    "a-spin": Spin,
  },
  data() {
    return {
      creatingTermination: false,

      newTermination: {
        reason: undefined,
        termination_date: undefined,
      },

      isSwitchChecked: false,

      associate: undefined,
      terminateAll: false,

      loadingAssociate: false,
    };
  },
  props: {
    associateId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    associateId: {
      handler(newVal) {
        if (newVal) {
          this.fetchAssociate();
        }
      },
    },
  },
  computed: {
    isFutureTermination() {
      return (
        this.associate?.resigned_date &&
        moment()
          .utc()
          .startOf("day")
          .isSameOrBefore(this.associate?.resigned_date)
      );
    },
    isTerminated() {
      return !this.associate?.termination ?? false;
    },
    getStatus() {
      if (this.associate.is_active) {
        return {
          title: "ACTIVE",
          color: StatusTagColors.GREEN,
        };
      } else {
        return {
          title: "DISABLED",
          color: StatusTagColors.RED,
        };
      }
    },
    terminationAuthorName() {
      if (this.isTerminated) {
        return "-";
      }

      const suspensionAuthor = this.associate.termination.author;
      return Util.combineAssociateName(
        suspensionAuthor.first_name,
        suspensionAuthor.last_name
      );
    },
    saveTerminationDisabled() {
      return (
        !this.newTermination.termination_date ||
        !this.newTermination.reason ||
        this.newTermination.reason.length === 0
      );
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
  },
  methods: {
    ...profileActions([
      "patchAssociateActivate",
      "patchAssociateDeactivate",
      "postAssociateTerminate",
      "postAssociateCancelTerminate",
      "getAssociateById",
    ]),

    formatDateString(dateString) {
      return moment(dateString).format("MM/DD/YYYY");
    },
    showNotification(type, message, description) {
      notification[type]({
        message: message,
        description: description,
      });
    },
    switchSetTermination() {
      this.creatingTermination = !this.creatingTermination;
    },
    resetNewTermination() {
      this.newTermination = {
        termination_date: undefined,
        reason: undefined,
      };
      this.terminateAll = false;
    },
    cancelTermination() {
      this.resetNewTermination();
      this.switchSetTermination();
    },

    handleCheckTerminateAll(e) {
      this.terminateAll = e.target.checked;
    },

    openTerminationConfirm() {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are about to terminate an associate. If you are sure - click OK.`,
        onOk: async () => {
          await this.setTermination();
        },
        onCancel() {},
      });
    },

    openActivityConfirm(checked) {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are about to ${
          checked ? "activate" : "disable"
        } an associate. ${
          checked
            ? "If you activate an associate, the termination will be canceled."
            : ""
        } If you are sure - click OK.`,
        onOk: async () => {
          await this.setActivity(checked);
        },
        onCancel() {},
      });
    },

    async setActivity(checked) {
      try {
        if (checked) {
          await this.patchAssociateActivate({
            associate_id: this.associateId,
          });
        } else {
          await this.patchAssociateDeactivate({
            associate_id: this.associateId,
          });
        }

        this.showNotification(
          "success",
          "Activation Set",
          `Associate has been successfully ${
            checked ? "activated" : "disabled"
          }`
        );

        this.fetchAssociate();
        this.$emit("reset");
      } catch (error) {
        this.showNotification("error", "Activation Set Error", error);
      }
    },

    async setTermination() {
      const payload = {
        user_id: this.associate.user_id,
        termination: {
          ...this.newTermination,
          termination_date: moment(this.newTermination.termination_date)
            .utc()
            .startOf("day")
            .toISOString(),
        },
        associate_id: this.terminateAll ? undefined : this.associateId,
      };

      const isFuture = moment(payload.termination.termination_date).isAfter(
        moment().utc().startOf("day")
      );

      try {
        await this.postAssociateTerminate(payload);

        this.showNotification(
          "success",
          "Termination Set",
          isFuture
            ? `Associate will be terminated at ${this.formatDateString(
                moment(payload.termination.termination_date).add(1, "day")
              )}`
            : "Associate is now terminated"
        );

        this.cancelTermination();
        this.$emit("reset");
      } catch (error) {
        this.showNotification("error", "Termination Set Error", error);
      }
    },

    async fetchAssociate() {
      this.loadingAssociate = true;
      try {
        const { associate } = await this.getAssociateById({
          associate_id: this.associateId,
        });

        this.associate = associate;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingAssociate = false;
      }
    },
  },

  mounted() {
    if (this.associateId) this.fetchAssociate();
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
  min-width: unset !important;
}
</style>
