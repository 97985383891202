var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('a-form',{attrs:{"layout":"inline","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"content-view"},[_c('div',{staticClass:"content-view-block"},[_c('div',[_c('h3',[_vm._v("Personal Information")]),_c('p',[_c('b',[_vm._v("First Name "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'firstName',
                  {
                    rules: [
                      {
                        required: true,
                        message: _vm.firstNameSuggestionMsg,
                        whitespace: true,
                      },
                      { pattern: _vm.namePattern, message: _vm.nameErrorMsg },
                    ],
                  },
                ]),expression:"[\n                  'firstName',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: firstNameSuggestionMsg,\n                        whitespace: true,\n                      },\n                      { pattern: namePattern, message: nameErrorMsg },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter first name","autocomplete":"new-first-name"},on:{"input":function($event){return _vm.handleCapitalize('firstName')}}})],1)],1),_c('p',[_c('b',[_vm._v("Last Name "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'lastName',
                  {
                    rules: [
                      {
                        required: true,
                        message: _vm.lastNameSuggestionMsg,
                        whitespace: true,
                      },
                      { pattern: _vm.namePattern, message: _vm.nameErrorMsg },
                    ],
                  },
                ]),expression:"[\n                  'lastName',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: lastNameSuggestionMsg,\n                        whitespace: true,\n                      },\n                      { pattern: namePattern, message: nameErrorMsg },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter last name","autocomplete":"new-last-name"},on:{"input":function($event){return _vm.handleCapitalize('lastName')}}})],1)],1),_c('p',[_c('b',[_vm._v("Email "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      { required: true, message: _vm.emailSuggestionMsg },
                      { type: 'email', message: _vm.emailErrorMsg },
                    ],
                  },
                ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      { required: true, message: emailSuggestionMsg },\n                      { type: 'email', message: emailErrorMsg },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter email","autocomplete":"new-email"}})],1)],1),_c('p',[_c('b',[_vm._v("Phone "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'phone',
                  {
                    rules: [
                      { required: true, message: _vm.phoneSuggestionMsg },
                      { pattern: /^\+\d{10,15}$/, message: _vm.phoneErrorMsg },
                    ],
                  },
                ]),expression:"[\n                  'phone',\n                  {\n                    rules: [\n                      { required: true, message: phoneSuggestionMsg },\n                      { pattern: /^\\+\\d{10,15}$/, message: phoneErrorMsg },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter phone","autocomplete":"new-phone"}})],1)],1),_c('p',[_c('b',[_vm._v("Password "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [
                      { required: true, message: _vm.passwordSuggestionMsg },
                      { min: 6, message: _vm.passwordErrorMsg },
                    ],
                  },
                ]),expression:"[\n                  'password',\n                  {\n                    rules: [\n                      { required: true, message: passwordSuggestionMsg },\n                      { min: 6, message: passwordErrorMsg },\n                    ],\n                  },\n                ]"}],staticClass:"password-input",attrs:{"placeholder":"Enter password","autocomplete":"new-password"}})],1)],1),_c('p',[_c('b',{staticClass:"associate-number-label"},[_vm._v("Associate Number "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'associateNumber',
                  {
                    rules: [
                      {
                        required: true,
                        message: _vm.associateNumberSuggestionMsg,
                      },
                    ],
                  },
                ]),expression:"[\n                  'associateNumber',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: associateNumberSuggestionMsg,\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter associate number","autocomplete":"new-associate-number"},on:{"input":_vm.handleAssociateNumberChange}})],1)],1),_c('p',[_c('b',[_vm._v("SSN")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'socialSecurityNumber',
                  {
                    rules: [
                      { required: false },
                      { pattern: /^\d{9}$/, message: _vm.ssnErrorMsg },
                    ],
                  },
                ]),expression:"[\n                  'socialSecurityNumber',\n                  {\n                    rules: [\n                      { required: false },\n                      { pattern: /^\\d{9}$/, message: ssnErrorMsg },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter SSN"}})],1)],1),_c('p',[_c('b',[_vm._v("Birth Date")]),_c('a-form-item',[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['birthDate', { rules: [{ required: false }] }]),expression:"['birthDate', { rules: [{ required: false }] }]"}],attrs:{"placeholder":"Select birth date","format":_vm.visualDateFormat,"disabled-date":_vm.disabledBirthDate},on:{"openChange":_vm.handleBirthDatePickerOpenChange}})],1)],1)]),_c('div',[_c('h3',[_vm._v("Project Information")]),_c('p',[_c('b',[_vm._v("Job Classification "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'projectRole',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a job classification!',
                      },
                    ],
                    initialValue: _vm.roleDefaultValue,
                  },
                ]),expression:"[\n                  'projectRole',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please select a job classification!',\n                      },\n                    ],\n                    initialValue: roleDefaultValue,\n                  },\n                ]"}],attrs:{"placeholder":"Select job classification"}},_vm._l((_vm.projectRoles),function(role){return _c('a-select-option',{key:role.id,attrs:{"value":role.id}},[_vm._v(_vm._s(role.name)+" ")])}),1)],1)],1),_c('p',[_c('b',[_vm._v("Schedule Type "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'scheduleType',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a schedule type!',
                      },
                    ],
                    initialValue: _vm.scheduleTypeDefaultValue,
                  },
                ]),expression:"[\n                  'scheduleType',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please select a schedule type!',\n                      },\n                    ],\n                    initialValue: scheduleTypeDefaultValue,\n                  },\n                ]"}],attrs:{"placeholder":"Select ST"}},_vm._l((_vm.scheduleTypes),function(type){return _c('a-select-option',{key:type.name,attrs:{"value":type.name}},[_vm._v(_vm._s(type.label)+" ")])}),1)],1)],1)]),_c('div',{staticClass:"view-button-group"},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingForm}},[_vm._v("Register")])],1)],1)]),_c('div',{staticClass:"content-view-block"},[_c('div',[_c('h3',[_vm._v("Address Information")]),_c('p',[_c('b',[_vm._v("Address")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address', { rules: [{ required: false }] }]),expression:"['address', { rules: [{ required: false }] }]"}],attrs:{"placeholder":"Enter address"}})],1)],1),_c('p',[_c('b',[_vm._v("City")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['city', { rules: [{ required: false }] }]),expression:"['city', { rules: [{ required: false }] }]"}],attrs:{"placeholder":"Enter city"}})],1)],1),(_vm.showStateField)?_c('p',[_c('b',[_vm._v("State")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'state',
                  { rules: [{ required: false }], initialValue: _vm.defaultState },
                ]),expression:"[\n                  'state',\n                  { rules: [{ required: false }], initialValue: defaultState },\n                ]"}],attrs:{"placeholder":"Enter state"}},_vm._l((_vm.statesList),function(state){return _c('a-select-option',{key:state.code,attrs:{"value":state.code}},[_vm._v(" "+_vm._s(state.name)+" ")])}),1)],1)],1):_vm._e(),_c('p',[_c('b',[_vm._v("Country")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'country',
                  { rules: [{ required: false }], initialValue: _vm.defaultCountry },
                ]),expression:"[\n                  'country',\n                  { rules: [{ required: false }], initialValue: defaultCountry },\n                ]"}],attrs:{"placeholder":"Enter country"},on:{"change":_vm.handleCountryStateChange}},_vm._l((_vm.countriesList),function(country){return _c('a-select-option',{key:country.code,attrs:{"value":country.code}},[_vm._v(" "+_vm._s(country.name)+" ")])}),1)],1)],1),_c('p',[_c('b',[_vm._v("Zip")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['Zipcode', { rules: [{ required: true, min: 5 }]}]),expression:"['Zipcode', { rules: [{ required: true, min: 5 }]}]"}],attrs:{"placeholder":"Enter zip"}})],1)],1)]),_c('div',[_c('h3',[_vm._v("Payment Information")]),_c('p',[_c('b',[_vm._v("Payment Type")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'paymentType',
                  {
                    rules: [{ required: false }],
                    initialValue: _vm.paymentTypeDefaultValue,
                  },
                ]),expression:"[\n                  'paymentType',\n                  {\n                    rules: [{ required: false }],\n                    initialValue: paymentTypeDefaultValue,\n                  },\n                ]"}],attrs:{"placeholder":"Select PT"},on:{"change":_vm.handlePaymentTypeChange}},_vm._l((_vm.paymentTypes),function(type){return _c('a-select-option',{key:type.id,attrs:{"value":type.id}},[_vm._v(_vm._s(type.name)+" ")])}),1)],1)],1),_c('p',[_c('b',[_vm._v("Payment Status")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'paymentStatus',
                  {
                    rules: [{ required: false }],
                    initialValue: _vm.paymentStatusDefaultValue,
                  },
                ]),expression:"[\n                  'paymentStatus',\n                  {\n                    rules: [{ required: false }],\n                    initialValue: paymentStatusDefaultValue,\n                  },\n                ]"}],attrs:{"placeholder":"Select Payment Status"}},_vm._l((_vm.paymentStatuses),function(status){return _c('a-select-option',{key:status.id,attrs:{"value":status.id}},[_vm._v(_vm._s(status.label)+" ")])}),1)],1)],1),(_vm.isEmployeePaymentTypeSelected && _vm.isFullTimePaymentStatus())?_c('p',[_c('b',[_vm._v("Payment Method")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'paymentMethod',
                  {
                    rules: [{ required: false }],
                    initialValue: _vm.paymentMethodDefaultValue,
                  },
                ]),expression:"[\n                  'paymentMethod',\n                  {\n                    rules: [{ required: false }],\n                    initialValue: paymentMethodDefaultValue,\n                  },\n                ]"}],attrs:{"placeholder":"Select PM"}},_vm._l((_vm.paymentMethods),function(method){return _c('a-select-option',{key:method.id,attrs:{"value":method.id}},[_vm._v(_vm._s(method.label)+" ")])}),1)],1)],1):_vm._e(),(_vm.isEmployeePaymentTypeSelected)?_c('p',[_c('b',[_vm._v("Rate, $")]),_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'rate',
                  { rules: [{ required: false }], initialValue: undefined },
                ]),expression:"[\n                  'rate',\n                  { rules: [{ required: false }], initialValue: undefined },\n                ]"}],attrs:{"placeholder":"Enter rate","min":0}})],1)],1):_vm._e(),(_vm.isContractorPaymentTypeSelected)?_c('p',[_c('b',[_vm._v("Agency "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":"","rules":[{ required: true, message: _vm.agencySuggestionMsg }]}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'selectedAgency',
                  {
                    rules: [
                      {
                        required: true,
                        message: _vm.agencySuggestionMsg,
                      },
                    ],
                  },
                ]),expression:"[\n                  'selectedAgency',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: agencySuggestionMsg,\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Select the agency"},on:{"change":_vm.handleAgencyChange}},_vm._l((_vm.agencies),function(agency){return _c('a-select-option',{key:agency.id,attrs:{"value":agency.id}},[_vm._v(" ["+_vm._s(agency.id)+"] - "+_vm._s(agency.name)+" ")])}),1)],1)],1):_vm._e(),(_vm.isContractorPaymentTypeSelected)?_c('p',[_c('b',[_vm._v("Hourly Rate, $")]),_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'rate',
                  { rules: [{ required: false }], initialValue: 0 },
                ]),expression:"[\n                  'rate',\n                  { rules: [{ required: false }], initialValue: 0 },\n                ]"}],attrs:{"placeholder":"Enter rate","min":0}})],1)],1):_vm._e()])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-user-with-associate-controls"},[_c('h3',[_vm._v("New User With Associate")])])
}]

export { render, staticRenderFns }