import apiClient from "@/services/api";

export default {
  async getProjects({ commit }, { query = "" }) {
    try {
      const { data } = await apiClient.post("api/search_project", { query });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setProjects", data);
    } catch (error) {
      throw error.message;
    }
  },

  async getUsers({ commit }, { query = "" }) {
    try {
      const { data } = await apiClient.post("api/tc_search_employee", {
        query: query && query.trim().length > 0 ? query : "/all",
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setUsers", data.associate_list);
    } catch (error) {
      throw error.message;
    }
  },

  async getAssociates({ commit, rootState }, { query = "", filter }) {
    try {
      const { data } = await apiClient.post("/api/associate/search", {
        project_id: rootState.applicationState.currentProject.id,
        query: query && query.trim().length > 0 ? query : "/all",
        filter,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setAssociates", data.associates);
    } catch (error) {
      throw error.message;
    }
  },
};
