import apiClient from "@/services/api";

export default {
  async getDocumentByType(_, { type, associate_id }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/type/get`, {
        type,
        associate_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data.document;
    } catch (error) {
      throw error.message;
    }
  },

  async downloadAssociateDocument(_, { associate_id, document_id }) {
    try {
      const { data } = await apiClient.post(
        "/api/document/associate/download",
        {
          associate_id,
          document_id,
        }
      );

      if ((data.error_code && data.error_code !== "0") || !data.file_url) {
        throw new Error("Please try again later");
      }

      return data.file_url;
    } catch (error) {
      throw error.message;
    }
  },

  async downloadAllAssociateDocuments(_, { associate_id }) {
    try {
      const { data } = await apiClient.post(
        "/api/document/associate/download/all",
        {
          associate_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data.file_url;
    } catch (error) {
      throw error.message;
    }
  },

  async sendAssociateDocument(_, { associate_id, document_id, email }) {
    try {
      const { data } = await apiClient.post(
        "/api/document/associate/send_email",
        {
          associate_id,
          document_id,
          email,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async sendAllAssociateDocument(_, { associate_id, email }) {
    try {
      const { data } = await apiClient.post(
        "/api/document/associate/send_email/all",
        {
          associate_id,
          email,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },
};
