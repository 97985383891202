<template>
  <div>
    <h3>Pay Codes</h3>
    <a-table
      :rowKey="(record) => record.id"
      :locale="{ emptyText: 'Pay Codes Not Found' }"
      size="small"
      :columns="columns"
      :data-source="payCodes"
      :pagination="false"
      :scroll="{ y: 300 }"
    >
      <span slot="attached" slot-scope="text, record">
        <a-tag class="m-0" color="#52bfff" v-show="record.attached"
          >Attached</a-tag
        >
      </span>
    </a-table>
  </div>
</template>

<script>
import { Table, Tag } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: shiftState } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-table": Table,
    "a-tag": Tag,
  },
  data() {
    return {
      columns: [
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Description",
          dataIndex: "description",
        },
        {
          title: "Type",
          dataIndex: "type",
          align: "center",
          width: "100px",
        },
        {
          title: "Value",
          dataIndex: "value",
          align: "center",
          width: "100px",
          customRender: (_, record) => {
            return record.type === "total"
              ? `$${record.value / 100}`
              : `$${record.value} hours`;
          },
        },
        {
          title: "Attached",
          dataIndex: "attached",
          align: "center",
          scopedSlots: { customRender: "attached" },
        },
      ],
    };
  },
  computed: {
    ...shiftState({
      payCodes: (state) => state.shiftPayCodes,
    }),
  },
};
</script>

<style scoped>
::v-deep .ant-table-footer {
  padding: 8px;
}

::v-deep .ant-table-header {
  scrollbar-width: none;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 8px;
}
</style>
