<template>
  <div>
    <h3>Actions</h3>
    <actions-info :list="actions" />
  </div>
</template>

<script>
import Locations from "@/components/locations.vue";
import { Table } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: shiftState } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-table": Table,
    "actions-info": Locations,
  },
  computed: {
    ...shiftState({
      actions: (state) => state.shiftActions,
    }),
  },
};
</script>

<style scoped>
::v-deep .ant-table-footer {
  padding: 8px;
}

::v-deep .ant-table-header {
  scrollbar-width: none;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 8px;
}
</style>
