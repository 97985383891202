<template>
  <a-descriptions :column="1" :title="title" size="small" class="p-0">
    <a-descriptions-item label="Date"
      >{{ formatDateRange(times.start, times.end) }}
    </a-descriptions-item>
    <a-descriptions-item label="Time"
      >{{ timeRangeToString(times.start, times.end) }}
    </a-descriptions-item>
    <a-descriptions-item label="Duration">
      {{ minutesToHoursAndMinutes(times.duration) }}</a-descriptions-item
    >
    <a-descriptions-item label="Lunch">{{
      timeRangeToString(times.lunch_start, times.lunch_end)
    }}</a-descriptions-item>
    <a-descriptions-item label="Lunch Duration">
      {{ minutesToHoursAndMinutes(times.lunch_duration) }}
    </a-descriptions-item>
    <a-descriptions-item label="Second Lunch">
      {{
        timeRangeToString(times.second_lunch_start, times.second_lunch_end)
      }}</a-descriptions-item
    >
    <a-descriptions-item label="Second Lunch Duration">
      {{ minutesToHoursAndMinutes(times.second_lunch_duration) }}
    </a-descriptions-item>
    <a-descriptions-item label="Work Hours"
      >{{ workHours }}
    </a-descriptions-item>
    <a-descriptions-item label="Pay Hours">{{ payHours }} </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { Descriptions } from "ant-design-vue";
import shiftsHelper from "@/helpers/shifts";

export default {
  components: {
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
  },
  mixins: [shiftsHelper],
  props: {
    title: {
      type: String,
      default: "",
    },
    times: {
      type: Object,
      default: {},
    },
  },
  computed: {
    workHours() {
      const {
        start,
        end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = this.times;

      const formatted = this.formatWorkHours(
        start,
        end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end
      );

      return formatted ?? " - ";
    },

    payHours() {
      const {
        scheduled_start,
        scheduled_end,
        start,
        end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = this.times;

      const formatted = this.formatPayHours(
        scheduled_start,
        scheduled_end,
        start,
        end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end
      );

      return formatted ?? " - ";
    },
  },
  methods: {
    timeRangeToString(start, end) {
      if (!start || !end) {
        return " - ";
      }

      const { start: startTime, end: endTime } = this.formatTimeRange(
        start,
        end
      );

      return `${startTime} - ${endTime}`;
    },
  },
};
</script>

<style scoped>
::v-deep .ant-descriptions-title {
  text-align: center;
  margin: 0;
  padding: 8px;
  border-radius: 2px 2px 0 0;
  border-bottom: none;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  text-align: right !important;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  justify-content: space-between;
}
</style>
