<template>
  <div>
    <confirmation-promise ref="confirmation" />
    <alert-modal ref="alertModal" />
    <locations-list ref="locationsList" />
    <files-list ref="filesList" />

    <a-modal
      :title="'Add Attachments to Shift #' + shift_id"
      v-model="addAttachmentsVisible"
    >
      <shift-attachments-upload
        :shift-id="shift_id"
        @document-uploaded="loadData"
      />
      <template slot="footer">
        <a-button type="primary" @click="closeAddAttachments"
          >Complete</a-button
        >
      </template>
    </a-modal>

    <a-modal title="Shift Already Exists" v-model="shiftAlreadyExistsVisible">
      <div>
        <span>
          The shift put on the schedule for the same time already exists. Do you
          want to edit it?
        </span>
      </div>
      <template slot="footer">
        <a-button @click="closeExistingShiftModal">Cancel</a-button>
        <a-button type="primary" @click="editExistingShift">Edit</a-button>
      </template>
    </a-modal>

    <div>
      <h2 class="view-header">Associate Shift</h2>

      <div class="table_controls_and_data">
        <div class="table_data_wrapper">
          <div>
            <a-row style="display: flex">
              <Space>
                <b style="padding-left: 24px">Associate #</b>
                <b>{{ this.employee_id }}</b>
                <b>{{ this.employee_name }}</b>
              </Space>
            </a-row>
            <a-row v-if="this.shift_id" style="display: flex">
              <Space>
                <b style="padding-left: 24px">Shift #</b>
                <b>{{ this.shift_id }}</b>
              </Space>
            </a-row>
          </div>
          <a-button @click="getBack">Back</a-button>
        </div>
        <div class="table_controls_wrapper">
          <div v-if="shift_id" class="table_params">
            <a-button
              icon="environment"
              :title="
                this.locations && this.locations.length == 0
                  ? 'Show locations'
                  : 'No locations found'
              "
              :disabled="this.locations && this.locations.length == 0"
              @click="showLocations"
            >
              Locations
            </a-button>
            <a-button
              icon="file-image"
              :title="
                this.files && this.files.length == 0
                  ? 'Show attachments'
                  : 'No attachments found'
              "
              :disabled="this.files && this.files.length == 0"
              @click="showAttachments"
            >
              Attachments
            </a-button>
            <a-button icon="plus" @click="openAddAttachments">
              Add Attachments
            </a-button>
          </div>
        </div>
      </div>

      <div class="ui segment">
        <a-row class="timecard-params-row" :gutter="16">
          <a-col :span="8">
            <a-card
              title="Work Time"
              :tab-list="workTimeTabList"
              :active-tab-key="workTimeCardKey"
              @tabChange="(key) => onTabChange(key, 'workTimeCardKey')"
            >
              <a-date-picker
                :show-time="{
                  format: 'h:mm a',
                  use12Hours: true,
                  defaultValue: startOfCurrentDay,
                }"
                v-model="pickerWorkStart"
                :disabled-date="disabledStartWorkDate"
                :disabled-time="disabledDateTimeWorkStart"
                :default-picker-value="pickerWorkStart || pickerWorkEnd"
                :format="dateFormat"
                placeholder="Start"
              />
              <a-date-picker
                :show-time="{
                  format: 'h:mm a',
                  use12Hours: true,
                  defaultValue: startOfCurrentDay,
                }"
                v-model="pickerWorkEnd"
                :disabled-date="disabledEndWorkDate"
                :disabled-time="disabledDateTimeWorkEnd"
                :default-picker-value="pickerWorkEnd || pickerWorkStart"
                :format="dateFormat"
                placeholder="End"
              />
              <div v-if="payCodeDuration === 0" class="duration">
                {{ getHoursDurationString(pickerWorkStart, pickerWorkEnd) }}
              </div>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card
              title="First Lunch Time"
              :tab-list="lunchTabList"
              :active-tab-key="firstLunchCardKey"
              @tabChange="(key) => onTabChange(key, 'firstLunchCardKey')"
            >
              <a-date-picker
                :show-time="{
                  format: 'h:mm a',
                  use12Hours: true,
                  defaultValue: startOfCurrentDay,
                }"
                v-model="pickerLunchStart"
                :disabled-date="disabledStartFirstLunchDate"
                :disabled-time="disabledDateTimeLunchStart"
                :default-picker-value="pickerLunchStart || pickerLunchEnd"
                :format="dateFormat"
                placeholder="Start"
              />
              <a-date-picker
                :show-time="{
                  format: 'h:mm a',
                  use12Hours: true,
                  defaultValue: startOfCurrentDay,
                }"
                v-model="pickerLunchEnd"
                :disabled-date="disabledEndFirstLunchDate"
                :disabled-time="disabledDateTimeLunchEnd"
                :default-picker-value="pickerLunchEnd || pickerLunchStart"
                :format="dateFormat"
                placeholder="End"
              />
              <div
                v-if="
                  isSignedFirstLunchActive &&
                  actualFirstLunchExists &&
                  !partOfSignedFirstLunchExists
                "
                class="insert-lunch"
              >
                <a-button type="primary" @click="insertFirstLunch"
                  >Insert From Actual</a-button
                >
              </div>
              <div v-if="payCodeDuration === 0" class="duration">
                {{ getHoursDurationString(pickerLunchStart, pickerLunchEnd) }}
              </div>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card
              title="Second Lunch Time"
              :tab-list="lunchTabList"
              :active-tab-key="secondLunchCardKey"
              @tabChange="(key) => onTabChange(key, 'secondLunchCardKey')"
            >
              <a-date-picker
                :show-time="{
                  format: 'h:mm a',
                  use12Hours: true,
                  defaultValue: startOfCurrentDay,
                }"
                v-model="pickerSecondLunchStart"
                :disabled-date="disabledStartSecondLunchDate"
                :disabled-time="disabledDateTimeSecondLunchStart"
                :default-picker-value="
                  pickerSecondLunchEnd || pickerSecondLunchStart
                "
                :format="dateFormat"
                placeholder="Start"
              />
              <a-date-picker
                :show-time="{
                  format: 'h:mm a',
                  use12Hours: true,
                  defaultValue: startOfCurrentDay,
                }"
                v-model="pickerSecondLunchEnd"
                :disabled-date="disabledEndSecondLunchDate"
                :disabled-time="disabledDateTimeSecondLunchEnd"
                :default-picker-value="
                  pickerSecondLunchStart || pickerSecondLunchEnd
                "
                :format="dateFormat"
                placeholder="End"
              />
              <div
                v-if="
                  isSignedSecondLunchActive &&
                  actualSecondLunchExists &&
                  !partOfSignedSecondLunchExists
                "
                class="insert-lunch"
              >
                <a-button type="primary" @click="insertSecondLunch"
                  >Insert From Actual</a-button
                >
              </div>
              <div v-if="payCodeDuration === 0" class="duration">
                {{
                  getHoursDurationString(
                    pickerSecondLunchStart,
                    pickerSecondLunchEnd
                  )
                }}
              </div>
            </a-card>
          </a-col>
          <!--          <a-col :span="6">-->
          <!--            <a-card-->
          <!--                v-if="payCodes"-->
          <!--                title="Pay Code"-->
          <!--            >-->
          <!--              <div class="pay-code-card">-->
          <!--                <div class="pay-code-card-row">-->
          <!--                  <a-select class="select-pay-code" v-model="payCode">-->
          <!--                    <a-select-option :value="0" :key="0">-->
          <!--                      <span>None</span>-->
          <!--                    </a-select-option>-->
          <!--                    <a-select-option v-for="pc in payCodes" :value="pc.id" :key="pc.id">-->
          <!--                      {{ pc.description }}-->
          <!--                    </a-select-option>-->
          <!--                  </a-select>-->
          <!--                </div>-->
          <!--                <div v-if="payCode !== 0" class="pay-code-card-row">-->
          <!--                  <a-slider class="pay-code-card-slider" v-model="payCodeDuration" :min="0" :max="shiftDuration"-->
          <!--                            :step="0.01"/>-->
          <!--                  <a-input-number class="pay-code-card-input-number" v-model="payCodeDuration" :min="0"-->
          <!--                                  :max="shiftDuration" :step="0.01"/>-->
          <!--                </div>-->
          <!--                <div class="card-description">-->
          <!--                  Select a pay code and it's duration-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </a-card>-->
          <!--          </a-col>-->
        </a-row>
      </div>
      <div>
        <a-button id="save" type="primary" size="large" @click="editCard">{{
          this.buttonName
        }}</a-button>
        <a-button size="large" @click="loadData">Reset</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Col,
  DatePicker,
  Input,
  Row,
  Icon,
  Button,
  Space,
  Card,
  notification,
  Slider,
  InputNumber,
  Select,
  Modal,
} from "ant-design-vue";
import ConfirmationPromise from "@/components/confirmation-promise";
import "vue-easytable/libs/theme-default/index.css";

import api from "@/api";
import Util from "@/util";
import moment from "moment-timezone";
import AlertModal from "@/components/alert-modal";
import LocationsList from "@/components/locations-list";
import FilesList from "@/components/files-list";
import antd from "ant-design-vue";
import router from "@/router";
import ShiftAttachmentsUpload from "@/modules/shifts/components/shift-attachments-upload.vue";

export default {
  components: {
    ShiftAttachmentsUpload,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-date-picker": DatePicker,
    "a-range-picker": DatePicker.RangePicker,
    "a-input": Input,
    "a-input-number": InputNumber,
    "a-row": Row,
    "a-col": Col,
    "a-button": Button,
    "a-card": Card,
    "a-slider": Slider,
    "a-modal": Modal,
    "confirmation-promise": ConfirmationPromise,
    "alert-modal": AlertModal,
    "locations-list": LocationsList,
    "files-list": FilesList,
    Space,
    Icon,
  },

  mixins: [api],

  data: () => ({
    edited: false,
    query: null,
    //date: null,
    formError: null,

    scheduled_start: null,
    scheduled_end: null,
    work_start: null,
    work_end: null,
    signed_start: null,
    signed_end: null,

    lunch_start: null,
    lunch_end: null,
    signed_lunch_start: null,
    signed_lunch_end: null,

    second_lunch_start: null,
    second_lunch_end: null,
    signed_second_lunch_start: null,
    signed_second_lunch_end: null,

    employee_id: null,
    shift_id: null,
    employee_name: null,
    buttonName: null,
    endInWorkArea: null,
    dateFormat: "MM/DD/YYYY hh:mm A",

    startOpen: false,
    endOpen: false,

    payCode: 0,
    payCodeDuration: 0,

    lunchTabList: [
      {
        key: "Actual",
        tab: "Actual",
      },
      {
        key: "Signed",
        tab: "Signed",
      },
    ],

    workTimeTabList: [
      {
        key: "Scheduled",
        tab: "Scheduled",
      },
      {
        key: "Actual",
        tab: "Actual",
      },
      {
        key: "Signed",
        tab: "Signed",
      },
    ],

    workTimeCardKey: "Signed",
    firstLunchCardKey: "Signed",
    secondLunchCardKey: "Signed",

    files: [],
    locations: [],
    payCodes: [],

    addAttachmentsVisible: false,

    shiftAlreadyExistsVisible: false,
    existingShiftId: null,
  }),

  computed: {
    pickerWorkStart: {
      get() {
        switch (this.workTimeCardKey) {
          case "Scheduled":
            return this.scheduled_start;
          case "Actual":
            return this.work_start;
          case "Signed":
            return this.signed_start;
        }
      },
      set(val) {
        switch (this.workTimeCardKey) {
          case "Scheduled":
            this.scheduled_start = val;
            break;
          case "Actual":
            this.work_start = val;
            break;
          case "Signed":
            this.signed_start = val;
            break;
        }
      },
    },
    pickerWorkEnd: {
      get() {
        switch (this.workTimeCardKey) {
          case "Scheduled":
            return this.scheduled_end;
          case "Actual":
            return this.work_end;
          case "Signed":
            return this.signed_end;
        }
      },
      set(val) {
        switch (this.workTimeCardKey) {
          case "Scheduled":
            this.scheduled_end = val;
            break;
          case "Actual":
            this.work_end = val;
            break;
          case "Signed":
            this.signed_end = val;
            break;
        }
      },
    },

    pickerLunchStart: {
      get() {
        switch (this.firstLunchCardKey) {
          case "Actual":
            return this.lunch_start;
          case "Signed":
            return this.signed_lunch_start;
        }
      },
      set(val) {
        switch (this.firstLunchCardKey) {
          case "Actual":
            this.lunch_start = val;
            break;
          case "Signed":
            this.signed_lunch_start = val;
            break;
        }
      },
    },
    pickerLunchEnd: {
      get() {
        switch (this.firstLunchCardKey) {
          case "Actual":
            return this.lunch_end;
          case "Signed":
            return this.signed_lunch_end;
        }
      },
      set(val) {
        switch (this.firstLunchCardKey) {
          case "Actual":
            this.lunch_end = val;
            break;
          case "Signed":
            this.signed_lunch_end = val;
            break;
        }
      },
    },
    pickerSecondLunchStart: {
      get() {
        switch (this.secondLunchCardKey) {
          case "Actual":
            return this.second_lunch_start;
          case "Signed":
            return this.signed_second_lunch_start;
        }
      },
      set(val) {
        switch (this.secondLunchCardKey) {
          case "Actual":
            this.second_lunch_start = val;
            break;
          case "Signed":
            this.signed_second_lunch_start = val;
            break;
        }
      },
    },
    pickerSecondLunchEnd: {
      get() {
        switch (this.secondLunchCardKey) {
          case "Actual":
            return this.second_lunch_end;
          case "Signed":
            return this.signed_second_lunch_end;
        }
      },
      set(val) {
        switch (this.secondLunchCardKey) {
          case "Actual":
            this.second_lunch_end = val;
            break;
          case "Signed":
            this.signed_second_lunch_end = val;
            break;
        }
      },
    },

    shiftDuration() {
      if (this.signed_start && this.signed_end) {
        return this.getHoursDuration(this.signed_start, this.signed_end);
      }

      return 0;
    },

    startOfCurrentDay() {
      return moment().startOf("day");
    },

    isSignedFirstLunchActive() {
      return this.firstLunchCardKey == "Signed";
    },

    isSignedSecondLunchActive() {
      return this.secondLunchCardKey == "Signed";
    },

    actualFirstLunchExists() {
      return this.lunch_start && this.lunch_end;
    },

    partOfSignedFirstLunchExists() {
      return this.signed_lunch_start || this.signed_lunch_end;
    },

    actualSecondLunchExists() {
      return this.second_lunch_start && this.second_lunch_end;
    },

    partOfSignedSecondLunchExists() {
      return this.signed_second_lunch_start || this.signed_second_lunch_end;
    },
  },

  watch: {
    formError() {
      if (this.formError != null) {
        notification["error"]({
          message: "Error",
          description: this.formError,
        });
        this.formError = null;
      }
    },
    scheduled_start() {
      this.edited = true;
    },
    scheduled_end() {
      this.edited = true;
    },
    work_start() {
      this.edited = true;
    },
    work_end() {
      this.edited = true;
    },
    signed_start() {
      this.edited = true;
    },
    signed_end() {
      this.edited = true;
    },
    lunch_start() {
      this.edited = true;
    },
    lunch_end() {
      this.edited = true;
    },
    signed_lunch_start() {
      this.edited = true;
    },
    signed_lunch_end() {
      this.edited = true;
    },
    second_lunch_start() {
      this.edited = true;
    },
    second_lunch_end() {
      this.edited = true;
    },
    signed_second_lunch_start() {
      this.edited = true;
    },
    signed_second_lunch_end() {
      this.edited = true;
    },
    payCode() {
      this.edited = true;
    },
    payCodeDuration() {
      this.edited = true;
    },

    $route: function () {
      Object.assign(this.$data, this.$options.data());
      this.query = { ...this.$route.query };
      this.edited = false;

      this.loadData();
    },
  },

  methods: {
    async loadData() {
      this.apiGetPayCodes().then((response) => {
        this.payCodes = response.body.paycodes;
      });

      if (this.query.id) {
        this.buttonName = "Save";

        await this.apiGetAssociateShift(this.query.id)
          .then((data) => {
            const okFunction = () => {
              this.employee_id = data.body.employee_id;
              this.shift_id = data.body.shift_id;

              this.work_start = data.body.work_start.length
                ? Util.convertDateTimeToCurrentTimeZone(data.body.work_start)
                : null;
              this.work_end = data.body.work_end.length
                ? Util.convertDateTimeToCurrentTimeZone(data.body.work_end)
                : null;
              this.scheduled_start = data.body.scheduled_start.length
                ? Util.convertDateTimeToCurrentTimeZone(
                    data.body.scheduled_start
                  )
                : null;
              this.scheduled_end = data.body.scheduled_end.length
                ? Util.convertDateTimeToCurrentTimeZone(data.body.scheduled_end)
                : null;
              this.signed_start = data.body.signed_start.length
                ? Util.convertDateTimeToCurrentTimeZone(data.body.signed_start)
                : null;
              this.signed_end = data.body.signed_end.length
                ? Util.convertDateTimeToCurrentTimeZone(data.body.signed_end)
                : null;

              this.lunch_start = data.body.lunch_start.length
                ? Util.convertDateTimeToCurrentTimeZone(data.body.lunch_start)
                : null;
              this.lunch_end = data.body.lunch_end.length
                ? Util.convertDateTimeToCurrentTimeZone(data.body.lunch_end)
                : null;
              this.signed_lunch_start = data.body.signed_lunch_start.length
                ? Util.convertDateTimeToCurrentTimeZone(
                    data.body.signed_lunch_start
                  )
                : null;
              this.signed_lunch_end = data.body.signed_lunch_end.length
                ? Util.convertDateTimeToCurrentTimeZone(
                    data.body.signed_lunch_end
                  )
                : null;

              this.second_lunch_start = data.body.second_lunch_start.length
                ? Util.convertDateTimeToCurrentTimeZone(
                    data.body.second_lunch_start
                  )
                : null;
              this.second_lunch_end = data.body.second_lunch_start.length
                ? Util.convertDateTimeToCurrentTimeZone(
                    data.body.second_lunch_end
                  )
                : null;
              this.signed_second_lunch_start = data.body
                .signed_second_lunch_start.length
                ? Util.convertDateTimeToCurrentTimeZone(
                    data.body.signed_second_lunch_start
                  )
                : null;
              this.signed_second_lunch_end = data.body.signed_second_lunch_end
                .length
                ? Util.convertDateTimeToCurrentTimeZone(
                    data.body.signed_second_lunch_end
                  )
                : null;

              this.employee_name = Util.combineAssociateName(
                data.body.first_name,
                data.body.last_name
              );

              this.endInWorkArea = data.body.end_in_wa;
              this.locations = data.body.loc_list;
              this.files = data.body.files;

              // if (data.body.associate_pay_code) {
              //   this.payCode = data.body.associate_pay_code.paycode.id;
              //   this.payCodeDuration = data.body.associate_pay_code.duration;
              // }
            };
            Util.handleAPIcallResult(data, okFunction);
          })
          .catch((error) => {
            console.error(error);
            Util.handleServerError(error);
          });
      } else if (this.query.employeeId) {
        this.cleanData();

        this.buttonName = "Add";
        this.employee_id = this.query.employeeId;

        this.apiGetAssociate(this.employee_id).then((response) => {
          this.employee_name = Util.combineAssociateName(
            response.body.first_name,
            response.body.last_name
          );
        });
      } else {
        this.$router.resolve("/TimeCards");
      }
    },
    validateForm() {
      if (this.employee_id === null) {
        this.formError = "Associate is not selected";
        return false;
      }

      const lunch_warn = "Lunch must be during of work time";

      if (!Util.isEmpty(this.lunch_start) && !Util.isEmpty(this.lunch_end)) {
        if (!Util.compareDatesFirstLess(this.lunch_start, this.lunch_end)) {
          this.formError = "Lunch end could not be earlier than lunch start";
          return false;
        }
      }

      if (
        !Util.isEmpty(this.second_lunch_start) &&
        !Util.isEmpty(this.second_lunch_end)
      ) {
        if (
          !Util.compareDatesFirstLess(
            this.second_lunch_start,
            this.second_lunch_end
          )
        ) {
          this.formError =
            "Second lunch end could not be earlier than second lunch start";
          return false;
        }
      }

      if (!Util.isEmpty(this.lunch_start)) {
        if (
          !Util.compareDatesFirstLessOrEqual(this.work_start, this.lunch_start)
        ) {
          this.formError = lunch_warn;
          return false;
        }
        if (!Util.compareDatesFirstLess(this.lunch_start, this.work_end)) {
          this.formError = lunch_warn;
          return false;
        }
      }

      if (!Util.isEmpty(this.signed_lunch_start)) {
        if (
          !Util.compareDatesFirstLessOrEqual(
            this.signed_start,
            this.signed_lunch_start
          )
        ) {
          this.formError = lunch_warn;
          return false;
        }
        if (
          !Util.compareDatesFirstLess(this.signed_lunch_start, this.signed_end)
        ) {
          this.formError = lunch_warn;
          return false;
        }
      }

      if (!Util.isEmpty(this.lunch_end)) {
        if (!Util.compareDatesFirstLessOrEqual(this.lunch_end, this.work_end)) {
          this.formError = lunch_warn;
          return false;
        }
      }

      if (!Util.isEmpty(this.signed_lunch_end)) {
        if (
          !Util.compareDatesFirstLessOrEqual(
            this.signed_lunch_end,
            this.signed_end
          )
        ) {
          this.formError = lunch_warn;
          return false;
        }
      }

      if (!Util.isEmpty(this.second_lunch_start)) {
        if (
          !Util.compareDatesFirstLessOrEqual(
            this.work_start,
            this.second_lunch_start
          ) ||
          !Util.compareDatesFirstLessOrEqual(
            this.signed_start,
            this.signed_second_lunch_start
          )
        ) {
          this.formError = lunch_warn;
          return false;
        }
        if (
          !Util.compareDatesFirstLess(this.second_lunch_start, this.work_end) ||
          !Util.compareDatesFirstLess(
            this.signed_second_lunch_start,
            this.signed_end
          )
        ) {
          this.formError = lunch_warn;
          return false;
        }
      }

      if (!Util.isEmpty(this.signed_second_lunch_start)) {
        if (
          !Util.compareDatesFirstLessOrEqual(
            this.signed_start,
            this.signed_second_lunch_start
          )
        ) {
          this.formError = lunch_warn;
          return false;
        }

        if (
          !Util.compareDatesFirstLess(
            this.signed_second_lunch_start,
            this.signed_end
          )
        ) {
          this.formError = lunch_warn;
          return false;
        }
      }

      if (!Util.isEmpty(this.second_lunch_end)) {
        if (
          !Util.compareDatesFirstLessOrEqual(
            this.second_lunch_end,
            this.work_end
          )
        ) {
          this.formError = lunch_warn;
          return false;
        }
      }

      if (!Util.isEmpty(this.signed_second_lunch_end)) {
        if (
          !Util.compareDatesFirstLessOrEqual(
            this.signed_second_lunch_end,
            this.signed_end
          )
        ) {
          this.formError = lunch_warn;
          return false;
        }
      }

      if (
        (!Util.isEmpty(this.signed_start) && Util.isEmpty(this.signed_end)) ||
        (Util.isEmpty(this.signed_start) && !Util.isEmpty(this.signed_end))
      ) {
        this.formError = "The signed time must have a start and an end";
        return false;
      }

      this.formError = null;
      return true;
    },
    async editCard() {
      if (!this.validateForm()) {
        return;
      }

      if (this.shift_id === null) {
        //add
        this.apiAddAssociateShift(
          this.employee_id,
          Util.convertDateTimeToUTC(this.scheduled_start ?? this.signed_start),
          Util.convertDateTimeToUTC(this.scheduled_end ?? this.signed_end),
          Util.convertDateTimeToUTC(this.work_start),
          Util.convertDateTimeToUTC(this.work_end),
          Util.convertDateTimeToUTC(this.signed_start),
          Util.convertDateTimeToUTC(this.signed_end),
          Util.convertDateTimeToUTC(this.lunch_start),
          Util.convertDateTimeToUTC(this.lunch_end),
          Util.convertDateTimeToUTC(this.signed_lunch_start),
          Util.convertDateTimeToUTC(this.signed_lunch_end),
          Util.convertDateTimeToUTC(this.second_lunch_start),
          Util.convertDateTimeToUTC(this.second_lunch_end),
          Util.convertDateTimeToUTC(this.signed_second_lunch_start),
          Util.convertDateTimeToUTC(this.signed_second_lunch_end)
        )
          .then(function (data) {
            const errorCode = Number(data.body.error_code);

            switch (errorCode) {
              case 0:
                notification["success"]({
                  message: "Info",
                  description: "Associate shift has been added",
                });
                history.back();
                break;
              case 1:
                notification["error"]({
                  message: "Error",
                  description: "Associate does not exist",
                });
                break;
              case 2:
                this.existingShiftId = data.body.existing_shift_id;
                this.shiftAlreadyExistsVisible = true;
                break;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.apiSetAssociateShift(
          this.shift_id,
          this.employee_id,
          Util.convertDateTimeToUTC(this.scheduled_start ?? this.signed_start),
          Util.convertDateTimeToUTC(this.scheduled_end ?? this.signed_end),
          Util.convertDateTimeToUTC(this.work_start),
          Util.convertDateTimeToUTC(this.work_end),
          Util.convertDateTimeToUTC(this.signed_start),
          Util.convertDateTimeToUTC(this.signed_end),
          Util.convertDateTimeToUTC(this.lunch_start),
          Util.convertDateTimeToUTC(this.lunch_end),
          Util.convertDateTimeToUTC(this.signed_lunch_start),
          Util.convertDateTimeToUTC(this.signed_lunch_end),
          Util.convertDateTimeToUTC(this.second_lunch_start),
          Util.convertDateTimeToUTC(this.second_lunch_end),
          Util.convertDateTimeToUTC(this.signed_second_lunch_start),
          Util.convertDateTimeToUTC(this.signed_second_lunch_end)
        )
          .then(function (data) {
            notification["success"]({
              message: "Info",
              description: "Associate shift has been saved",
            });
            history.back();
          })
          .catch((error) => {
            notification["error"]({
              message: "Error",
              description:
                "An error occurred while adding a shift. Please contact the administrator",
            });
          });
      }
    },
    getHoursDuration(begin, end) {
      if (begin == null || end == null) {
        return 0;
      }

      return end.diff(begin, "hours", true);
    },
    getHoursDurationString(begin, end) {
      return begin != null && end != null
        ? "Duration: " + Util.getFormattedDuration(begin, end)
        : "Enter an interval for counting the duration";
    },

    disabledStartWorkDate(startValue) {
      const endValue = this.pickerWorkEnd;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndWorkDate(endValue) {
      const startValue = this.pickerWorkStart;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledStartFirstLunchDate(startValue) {
      const endValue = this.pickerLunchEnd;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndFirstLunchDate(endValue) {
      const startValue = this.pickerLunchStart;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledStartSecondLunchDate(startValue) {
      const endValue = this.pickerSecondLunchEnd;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndSecondLunchDate(endValue) {
      const startValue = this.pickerSecondLunchStart;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },

    disabledDateTimeWorkStart() {
      return this.calculateDisabledDateTimeRange(
        this.pickerWorkStart,
        this.pickerWorkEnd,
        "start"
      );
    },
    disabledDateTimeWorkEnd() {
      return this.calculateDisabledDateTimeRange(
        this.pickerWorkStart,
        this.pickerWorkEnd,
        "end"
      );
    },
    disabledDateTimeLunchStart() {
      return this.calculateDisabledDateTimeRange(
        this.pickerLunchStart,
        this.pickerLunchEnd,
        "start"
      );
    },
    disabledDateTimeLunchEnd() {
      return this.calculateDisabledDateTimeRange(
        this.pickerLunchStart,
        this.pickerLunchEnd,
        "end"
      );
    },
    disabledDateTimeSecondLunchStart() {
      return this.calculateDisabledDateTimeRange(
        this.pickerSecondLunchStart,
        this.pickerSecondLunchEnd,
        "start"
      );
    },
    disabledDateTimeSecondLunchEnd() {
      return this.calculateDisabledDateTimeRange(
        this.pickerSecondLunchStart,
        this.pickerSecondLunchEnd,
        "end"
      );
    },

    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    calculateDisabledDateTimeRange(start, end, pickerType) {
      let disabledHours = () => [],
        disabledMinutes = () => [];

      if (start != null && end == null && start.diff(moment(), "days") == 0) {
        end = moment();
      } else if (
        start == null &&
        end != null &&
        end.diff(moment(), "days") == 0
      ) {
        start = moment();
      }

      if (start != null && end != null && start.isSame(end, "day")) {
        const hourRange = this.range(0, 24);
        const minutesRange = this.range(0, 60);

        switch (pickerType) {
          case "start":
            disabledHours = () => this.range(0, 24).slice(end.hours() + 1, 24);

            if (end.hours() == start.hours()) {
              disabledMinutes = () => minutesRange.slice(end.minutes() + 1, 60);
            }
            break;
          case "end":
            disabledHours = () => hourRange.slice(0, start.hours());

            if (start.hours() == end.hours()) {
              disabledMinutes = () => minutesRange.slice(0, start.minutes());
            }
            break;
        }
      }

      return { disabledHours, disabledMinutes };
    },

    async showAlertModal(header, text) {
      await this.$refs.alertModal.show({ header, text });
    },

    onTabChange(key, type) {
      this[type] = key;
    },

    async showLocations() {
      await this.$refs.locationsList.show({
        loc_list: this.locations,
      });
    },

    async showAttachments() {
      await this.$refs.filesList.show({
        title: "Attachments for shift #" + this.shift_id,
        file_list: this.files,
      });
    },

    openAddAttachments() {
      this.addAttachmentsVisible = true;
    },
    closeAddAttachments() {
      this.addAttachmentsVisible = false;
    },

    async getBack() {
      if (!this.edited) {
        this.$router.push({ path: "/shifts" });
        return;
      }

      antd.Modal.confirm({
        title: "Are you sure?",
        content:
          'You have unsaved changes. To save them - click on the "Save" ("Add") button at the bottom.',
        onOk() {
          router.back();
        },
        onCancel() {},
      });
    },

    beforeUnloadHandler() {
      if (this.edited) {
        event.returnValue = true;
      }
    },

    cleanData() {
      const query = this.query;
      const employeeId = this.employee_id;

      Object.assign(this.$data, this.$options.data());

      this.query = query;
      this.employee_id = employeeId;
    },

    insertFirstLunch() {
      if (!this.lunch_start || !this.lunch_end) {
        notification["error"]({
          message: "Error",
          description: "Fill actual lunch to insert",
        });
        return;
      }

      this.signed_lunch_start = this.lunch_start;
      this.signed_lunch_end = this.lunch_end;
    },

    insertSecondLunch() {
      if (!this.second_lunch_start || !this.second_lunch_end) {
        notification["error"]({
          message: "Error",
          description: "Fill actual lunch to insert",
        });
        return;
      }

      this.signed_second_lunch_start = this.second_lunch_start;
      this.signed_second_lunch_end = this.second_lunch_end;
    },

    closeExistingShiftModal() {
      this.shiftAlreadyExistsVisible = false;
      this.existingShiftId = null;
    },
    editExistingShift() {
      if (this.existingShiftId) {
        this.$router.push({
          path: "/time-card",
          query: { id: this.existingShiftId },
        });
      } else {
        notification["error"]({
          message: "Error",
          description:
            "An error occurred on redirect to existing shift. Please try again later or edit same shift manually",
        });
      }
    },
  },

  async created() {
    this.query = { ...this.$route.query };
    await this.loadData();

    this.edited = false;

    window.addEventListener("beforeunload", this.beforeUnloadHandler);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  },
};
</script>

<style>
.timecard-params-row {
  min-width: 900px;
}

.ant-card {
  height: 285px;
}

#save {
  margin: 10px;
}

.ui.segment {
  height: auto;
}

.ant-calendar-picker {
  margin: 3px;
  min-width: 200px !important;
}

.duration,
.insert-lunch {
  margin-top: 20px;
}

.pay-code-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 35px 0;
}

.pay-code-card-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.25rem;
}

.pay-code-card-slider {
  flex-grow: 1;
}

.pay-code-card-input-number {
  flex-shrink: 0;
}

.card-description {
  margin-top: calc(20px - 0.5rem);
}
</style>
