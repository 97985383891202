<template>
  <a-modal
    title="Shift Deletion"
    :visible="visible"
    :closable="false"
    @cancel="closeDeletionModal"
    :destroy-on-close="true"
  >
    <div>
      <span v-if="version && version > 1">
        Are you sure you want to delete shift #{{ shiftId }}
        or just revoke last signature?
      </span>
      <span v-else>
        Are you sure you want to delete shift #{{ shiftId }}?
      </span>
    </div>
    <template slot="footer">
      <a-button type="danger" @click="deleteSelectedShift"
        >Delete Shift</a-button
      >
      <a-button
        v-if="version && version > 1"
        type="danger"
        @click="deleteSelectedVersion"
      >
        Revoke Signature
      </a-button>
      <a-button type="primary" @click="closeDeletionModal">No</a-button>
    </template>
  </a-modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { Button, notification } from "ant-design-vue";

const { mapState, mapActions } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-button": Button,
  },
  props: {
    shiftId: {
      type: Number,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Function,
    },
    version: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      shift: (state) => state.shift.shift,
    }),

    canBeDeleted() {
      const paymentStatuses = [
        "auth_issues",
        "unpaid",
        "cancelled",
        "not_paid",
      ];

      const shiftStatuses = [
        "scheduled",
        "missed",
        "not_signed",
        "cancelled",
        "completed",
      ];

      return (
        paymentStatuses.includes(this.shift.payment_status) &&
        shiftStatuses.includes(this.shift.status)
      );
    },
  },
  methods: {
    ...mapActions(["deleteShift", "deleteVersion"]),

    closeDeletionModal() {
      this.$emit("update:visible", false);
    },

    async handleDeletion(action, successMessage) {
      if (!this.canBeDeleted) {
        this.showNotification(
          "warning",
          "Waning",
          "This shift can't be deleted cause of shift status or payment status"
        );
        this.closeDeletionModal();
        
        return;
      }

      try {
        await action();
        this.showNotification("success", "Success", successMessage);
        this.closeDeletionModal();
        this.$emit("edit");
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      }
    },

    async deleteSelectedShift() {
      await this.handleDeletion(
        () => this.deleteShift({ shift_id: this.shiftId }),
        `Shift #${this.shiftId} has been successfully deleted`
      );
    },

    async deleteSelectedVersion() {
      await this.handleDeletion(
        () =>
          this.deleteVersion({ shift_id: this.shiftId, version: this.version }),
        `Last signature of #${this.shiftId} has been successfully deleted`
      );
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },
  },
};
</script>
