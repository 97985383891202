import { render, staticRenderFns } from "./search-associates.vue?vue&type=template&id=01d1f64e&"
import script from "./search-associates.vue?vue&type=script&lang=js&"
export * from "./search-associates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports