export const SHIFT_STATUS = Object.freeze({
  scheduled: {
    title: "Scheduled",
    text: "Shift has been scheduled.",
    color: "#b4b4b4",
  },
  started: {
    title: "Started",
    text: "Shift is currently in progress.",
    color: "#52bfff",
  },
  missed: {
    title: "Missed",
    text: "Shift has been missed.",
    color: "#ff4646",
  },
  not_signed: {
    title: "Not Signed",
    text: "Shift is not signed.",
    color: "#ffb546",
  },
  cancelled: {
    title: "Cancelled",
    text: "Shift has been cancelled.",
    color: "#6C69FF",
  },
  completed: {
    title: "Completed",
    color: "#34db30",
    text: "Shift has been competed.",
  },
  deleted: {
    title: "Deleted",
    text: "Shift has been deleted.",
    color: "#ff4646",
  },
});

export const SHIFT_PAYMENT_REQUEST_STATUS = Object.freeze({
  unpaid: {
    title: "Unpaid",
    color: "#ff4646",
    text: "Payment for the shift is pending.",
  },
  auth_issues: {
    title: "Auth Issues",
    color: "#ffb546",
    text: "There are authorization issues with the shift.",
  },
  not_paid: {
    title: "Ready To Pay",
    color: "#34db30",
    text: "Shift has not been paid.",
  },
  draft: {
    title: "Draft Payment",
    color: "#21E29D",
    text: "Payment request is in draft status.",
  },
  paid: {
    title: "Paid",
    color: "#b4b4b4",
    text: "Shift has been paid.",
  },
  in_progress: {
    title: "Payment In Progress",
    color: "#52bfff",
    text: "Payment processing is in progress.",
  },
  partially_paid: {
    title: "Partially Paid",
    color: "#b4b4b4",
    text: "Shift has been partially paid.",
  },
  cancelled: {
    title: "Payment Cancelled",
    color: "#ffb546",
    text: "Payment was cancelled.",
  },
});

export const SHIFT_PAYMENT_STATUS = Object.freeze({
  paid: {
    title: "Paid",
    color: "#b4b4b4",
  },
  accepted: {
    title: "Accepted",
    color: "#52bfff",
  },
  not_paid: {
    title: "Not Paid",
    color: "#34db30",
  },
  cancelled: {
    title: "Cancelled",
    color: "#ffb546",
  },
  deleted: {
    title: "Deleted",
    color: "#ff4646",
  },
  approved: {
    title: "Approved",
    color: "#34db30",
  },
  denied: {
    title: "Denied",
    color: "#ff4646",
  },
});

export const SHIFT_ORIGIN_COLORS = Object.freeze({
  "Time Tracker": "#7432FF",
  Scheduler: "#52BFFF",
  Marketplace: "#FF4646",
});

export const COLUMNS = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 80,
    align: "center",
  },
  {
    title: "Associate",
    dataIndex: "name",
    scopedSlots: { customRender: "associate" },
  },
  {
    title: "Status",
    key: "status",
    scopedSlots: { customRender: "status" },
    align: "center",
  },
  {
    title: "Authorizations",
    key: "authorizations",
    scopedSlots: { customRender: "authorizations" },
    align: "center",
  },
  {
    title: "Origin",
    key: "origin",
    scopedSlots: { customRender: "origin" },
    align: "center",
  },
  {
    title: "Actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
    align: "center",
  },
];

export const NESTED_COLUMNS = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Date",
    key: "date",
    customRender: (_, record) => {
      const { start, end } = record;
      const formatted = start && end ? this.formatDateRange(start, end) : " - ";

      return <div>{formatted}</div>;
    },
  },
  {
    title: "Start",
    key: "start",
    dataIndex: "start",
    customRender: (_, record) => {
      const colorClass =
        record.type === "Signed" && record.signed_start_diff_info
          ? record.signed_start_diff_info.color
          : "";

      const formatted = record.start ? this.formatDate(record.start) : " - ";

      const mainDiv = <div class={`${colorClass}`}>{formatted}</div>;

      if (record.signed_start_diff_info) {
        const tooltipTitle = `Signed start time differs ${record.signed_start_diff_info.difference} minutes from tracked time.`;
        return (
          <a-tooltip>
            <template slot="title">{tooltipTitle}</template>
            {mainDiv}
          </a-tooltip>
        );
      }

      return mainDiv;
    },
  },
  {
    title: "End",
    key: "end",
    dataIndex: "end",
    customRender: (_, record) => {
      const colorClass =
        record.type === "Signed" && record.signed_end_diff_info
          ? record.signed_end_diff_info.color
          : "";

      const formatted = record.end ? this.formatDate(record.end) : " - ";

      const mainDiv = <div class={`${colorClass}`}>{formatted}</div>;

      if (record.signed_end_diff_info) {
        const tooltipTitle = `Signed end time differs ${record.signed_end_diff_info.difference} minutes from tracked time`;
        return (
          <a-tooltip>
            <template slot="title">{tooltipTitle}</template>
            {mainDiv}
          </a-tooltip>
        );
      }

      return mainDiv;
    },
  },
  {
    title: "Dur",
    key: "duration",
    customRender: (_, record) => {
      const { start, end } = record;

      const formatted = Util.getFormattedDuration(start, end);

      return <div>{formatted}</div>;
    },
  },
  {
    title: "Work Hrs",
    key: "work_hours",
    customRender: (_, record) => {
      const {
        start,
        end,
        first_lunch_start,
        first_lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = record;

      const formatted =
        this.formatWorkHours(
          start ? moment.utc(start) : null,
          end ? moment.utc(end) : null,
          first_lunch_start ? moment.utc(first_lunch_start) : null,
          first_lunch_end ? moment.utc(first_lunch_end) : null,
          second_lunch_start ? moment.utc(second_lunch_start) : null,
          second_lunch_end ? moment.utc(second_lunch_end) : null
        ) ?? " - ";

      return <div>{formatted}</div>;
    },
  },
  {
    title: "Pay Hrs",
    key: "pay_hours",
    customRender: (_, record) => {
      const {
        scheduled_start,
        scheduled_end,
        start,
        end,
        first_lunch_start,
        first_lunch_end,
        second_lunch_start,
        second_lunch_end,
        type,
      } = record;

      if (type === "Scheduled") {
        return " - ";
      }

      const formatted =
        this.formatPayHours(
          scheduled_start ? moment.utc(scheduled_start) : null,
          scheduled_end ? moment.utc(scheduled_end) : null,
          start ? moment.utc(start) : null,
          end ? moment.utc(end) : null,
          first_lunch_start ? moment.utc(first_lunch_start) : null,
          first_lunch_end ? moment.utc(first_lunch_end) : null,
          second_lunch_start ? moment.utc(second_lunch_start) : null,
          second_lunch_end ? moment.utc(second_lunch_end) : null
        ) ?? " - ";

      return <div>{formatted}</div>;
    },
  },
  {
    title: "L1 Start",
    key: "first_lunch_start",
    customRender: (_, record) => {
      const { first_lunch_start } = record;

      const formatted = first_lunch_start
        ? this.formatDate(first_lunch_start)
        : " - ";

      return <div>{formatted}</div>;
    },
  },
  {
    title: "L1 End",
    key: "first_lunch_end",
    customRender: (_, record) => {
      const { first_lunch_end } = record;

      const formatted = first_lunch_end
        ? this.formatDate(first_lunch_end)
        : " - ";

      return <div>{formatted}</div>;
    },
  },
  {
    title: "L1 Dur",
    customRender: (_, record) => {
      const { first_lunch_end, first_lunch_start } = record;

      const formatted = Util.getFormattedDuration(
        first_lunch_start,
        first_lunch_end
      );

      return <div>{formatted}</div>;
    },
  },
  {
    title: "L2 Start",
    key: "second_lunch_start",
    customRender: (_, record) => {
      const { second_lunch_start } = record;

      const formatted = second_lunch_start
        ? this.formatDate(second_lunch_start)
        : " - ";

      return <div>{formatted}</div>;
    },
  },
  {
    title: "L2 End",
    key: "second_lunch_end",
    customRender: (_, record) => {
      const { second_lunch_end } = record;

      const formatted = second_lunch_end
        ? this.formatDate(second_lunch_end)
        : " - ";

      return <div>{formatted}</div>;
    },
  },
  {
    title: "L2 Dur",
    customRender: (_, record) => {
      const { second_lunch_start, second_lunch_end } = record;

      const formatted = Util.getFormattedDuration(
        second_lunch_start,
        second_lunch_end
      );

      return <div>{formatted}</div>;
    },
  },
];

export const SHIFT_FORM_INITIAL_STATE = {
  duration: 0,
  work_start: null,
  work_end: null,
  lunch_duration: 0,
  lunch_start: null,
  lunch_end: null,
  second_lunch_duration: 0,
  second_lunch_start: null,
  second_lunch_end: null,
  actual_lunch_duration: 0,
  actual_lunch_end: null,
  actual_lunch_start: null,
  actual_second_lunch_duration: 0,
  actual_second_lunch_end: null,
  actual_second_lunch_start: null,
  actual_duration: 0,
  actual_work_end: null,
  actual_work_start: null,
  modified_by_admin: true,
  lunch_waived: false,
};

export const INITIAL_STATE = {
  shiftAuthorizations: [],
  shifts: {},
  shift: {},
  shiftForm: { ...SHIFT_FORM_INITIAL_STATE },
  shiftPayCodes: [],
  shiftCalculations: [],
  shiftPayments: [],
  shiftActions: [],
};
