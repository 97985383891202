<template>
  <a-modal
    title="Edit Pay Code"
    :visible="visible"
    width="480px"
    @cancel="closePayCodeModal"
  >
    <a-form-model
      layout="horizontal"
      :model="form"
      :rules="rules"
      ref="payCodeForm"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item :colon="false" label-align="left" label="Type">
        <a-select :value="type" disabled>
          <a-select-option value="attached">Attached</a-select-option>
          <a-select-option value="independent">Independent</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Pay Code"
        prop="pay_code"
      >
        <a-select v-model="payCodeProxy" placeholder="Select Pay Code">
          <a-select-option
            v-for="payCode in availablePayCodes"
            :key="payCode.id"
            :value="payCode.id"
          >
            {{ payCode.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        v-if="this.form.pay_code"
        :colon="false"
        label-align="left"
        :label="`Value, ${this.form.pay_code.type === 'total' ? '$' : 'hours'}`"
      >
        <a-input-number
          v-model="form.pay_code.value"
          :min="0"
          placeholder="Enter value"
        />
      </a-form-model-item>
    </a-form-model>

    <template slot="footer">
      <a-button @click="closePayCodeModal">Cancel</a-button>
      <a-button type="primary" :loading="loading" @click="submit"
        >Edit</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import {
  Button,
  FormModel,
  InputNumber,
  Modal,
  notification,
  Select,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions: shiftActions, mapState: shiftState } =
  createNamespacedHelpers("shifts");

const { mapState: payCodeState, mapActions: payCodeActions } =
  createNamespacedHelpers("paycode");

export default {
  components: {
    "a-modal": Modal,
    "a-form-model": FormModel,
    "a-form-model-item": FormModel.Item,
    "a-input-number": InputNumber,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shiftId: {
      type: Number,
      default: undefined,
    },
    edit: {
      type: Function,
    },
  },
  data() {
    return {
      form: {
        pay_code: undefined,
      },
      rules: {
        pay_code: [
          {
            required: true,
            message: "Please select pay code",
            trigger: "change",
            type: "object",
          },
        ],
      },
      type: "attached",

      loading: false,
    };
  },
  watch: {
    shift: {
      handler(newVal) {
        this.form.pay_code = newVal;

        if (!newVal.attached) {
          this.type = "independent";
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...payCodeState({
      payCodes: (state) => state.payCodes,
    }),
    ...shiftState({
      shift: (state) => state.shift.shift.pay_code,
    }),

    availablePayCodes() {
      switch (this.type) {
        case "attached":
          return this.payCodes.filter((item) => item.attached);
        case "independent":
          return this.payCodes.filter((item) => !item.attached);
      }
    },

    payCodeProxy: {
      get() {
        return this.form.pay_code ? Number(this.form.pay_code.id) : undefined;
      },
      set(value) {
        this.form.pay_code = this.payCodes.find((item) => item.id === value);
      },
    },
  },
  methods: {
    ...shiftActions(["updatePayCode"]),
    ...payCodeActions(["fetchPayCodes"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    closePayCodeModal() {
      this.$emit("update:visible", false);
    },

    // async

    async submit() {
      try {
        const valid = await new Promise((resolve) => {
          this.$refs.payCodeForm.validate(resolve);
        });

        if (!valid) {
          this.showNotification(
            "warning",
            "Validation Error",
            "Please fill in all required fields correctly."
          );

          return;
        }

        this.loading = true;

        const payload = { ...this.form, shift_id: this.shiftId };

        await this.updatePayCode(payload);

        this.closePayCodeModal();
        this.$emit("edit");
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchPayCodesInfo() {
      try {
        await this.fetchPayCodes({});
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },
  },
  mounted() {
    if (!this.payCodes.length) this.fetchPayCodesInfo();
  },
};
</script>
