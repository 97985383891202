<template>
  <a-modal
    title="Filter Visitors"
    :visible="visible"
    @cancel="handleCancel"
    @ok="applyFilters"
    ok-text="Apply Filters"
    cancel-text="Cancel"
  >
    <a-form :model="filters" layout="vertical">
      <a-form-item label="Visit Date">
        <a-range-picker
          v-model:value="filters.visitDate"
          :placeholder="['Select visit date range']"
          format="YYYY-MM-DD"
          style="width: 100%"
        />
      </a-form-item>

      <a-form-item label="Type">
        <a-select
          v-model:value="filters.type"
          allowClear
          placeholder="Select visit type"
          style="width: 100%"
        >
          <a-select-option value="independent">Independent</a-select-option>
          <a-select-option value="vendor_representative">Vendor Representative</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Name">
        <a-input
          v-model:value="filters.name"
          placeholder="Visitor's name"
          style="width: 100%"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { Modal, Form, Input, Select, DatePicker } from "ant-design-vue";

export default {
  name: "VisitorFiltersModal",
  components: {
    "a-modal": Modal,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-range-picker": DatePicker.RangePicker,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleCancel() {
      this.$emit("update:visible", false);
    },

    applyFilters() {
      this.$emit("update:visible", false);
      this.$emit("apply-filters", this.filters);
    },
  },
};
</script>

<style scoped>
a-form-item {
  margin-bottom: 16px;
}
</style>
